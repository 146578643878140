import { ListProps } from '@material-ui/core';
import { FC } from 'react';
import { BooleanField, Datagrid, DateField,List, TextField, TextInput } from 'react-admin';

const CustomersFilter = [
  <TextInput key="id" label="Поиск по id жалобы" source="id" alwaysOn />,
  <TextInput key="userId" label="Поиск по id отправителя" source="userId" alwaysOn />,
  <TextInput key="apartmentAdId" label="Поиск по id объявления" source="apartmentAdId" alwaysOn />,
];

const ApartmentAdComplaintList: FC<ListProps> = (props) => {
  return (
    <List filters={CustomersFilter} sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="ID жалобы" source="id" sortable={false} />
        <TextField label="ID отправителя" source="userId" sortable={false} />
        <TextField label="ID объявления" source="apartmentAdId" sortable={false} />
        <BooleanField label="Рассмотрена" source="isViewed" />
        <DateField locales="rus" showTime label="Создан" source="createdAt" />
        <DateField locales="rus" showTime label="Обновлен" source="updatedAt" />
      </Datagrid>
    </List>
  );
};

export default ApartmentAdComplaintList;
