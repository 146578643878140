import { AppConfig } from 'config';
import { regex } from 'const';
import crudProvider from 'ra-data-nestjsx-crud';
import { fetchUtils } from 'react-admin';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('accessToken');

  const replaceUrl = url.replaceAll(regex.arrayIndex, '').replaceAll(regex.filterCondition, '$contL');

  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(replaceUrl, options);
};

export default crudProvider(AppConfig.API_URL, httpClient
);
